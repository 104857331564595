.resultCard-container {
    display: flex;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 5px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.grow {
   transform: scale(1.1);
   background-color: rgba(124, 185, 54, 0.1);
   border: solid 1px rgba(124, 185, 54, 0.7)
}

.resultCard-img-container {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    padding: 5px 0px;
}

.resultCard-img-container > img {
    width: 100px;
    height: auto;
    margin-left: 20px;
    max-height:  -webkit-fill-available;
}

@media screen and (max-width: 1200px) {
    .resultCard-img-container > img {
        width: 80px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 400px) {
    .resultCard-img-container > img {
        width: 60px
    }
}

.resultCard-data-container {
    margin: 10px 10px 10px 30px;
    width: 100%;
}

.resultCard-data-container__subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #666666
}

@media screen and (max-width: 600px) {
    .resultCard-data-container__subtitle {
        font-size: 12px;
    }
}

.resultCard-data-container__details {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 200;
}

@media screen and (max-width: 600px) {
    .resultCard-data-container__details {
        font-size: 10px;
    }
}

