.cards-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body {
  border-radius: 10px !important;
  width: 360px;
  height: auto !important;
  margin: 7px 25px;
}

.card-content {
 padding: 20px;
 width: auto;
 height: 140px;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
}

.p-card {
  margin-top: 0px;
  margin-bottom: 10px;
}

.btn-green {
  color: white !important;
  background-color: #7cb936 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 8px 14px !important;
  margin: 0px 0px 8px 8px !important;
  border-radius: 5px !important;
}
.cards-loading {
  min-height: 970px;
  padding-top: 50px;
  margin: 0;
  display: flex;
}

@media screen and (max-width: 490px) {
  .card-body {
    width: auto;
    height: auto;
    margin: 5px 10px;
  }
  .cards-loading {
    min-height: 1080px;
    padding-top: 30px;
    margin: 0;
    display: flex;
  }
}

@media screen and (min-width: 900px) {
    .cards-container {
        width: auto;
        height: auto;
    }
    .card-body {
        width: 250px;
    }
    .cards-loading {
      max-height: 372px;
      min-height: 372px;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      position: relative;
      right: 20px
    }
}

@media screen and (min-width: 1200px) {
    .card-body {
        width: 330px;
        height: 275px;
        margin: 7px 25px;
    }
}