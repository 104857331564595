@import url(https://rsms.me/inter/inter.css);
@import url(https://rsms.me/inter/inter.css);
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden-scrollbar::-webkit-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.blankState {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px 40px 30px 40px;
    opacity: 0.7;
    z-index: -1;
}

@media screen and (max-width: 1000px) {
    .blankState {
      display: none;
    }
}

.blankState > h1 {
    width: 90%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: rgba(100,100,100, 0.6);
    font-weight: 500;
}

.blankState > img {
    width: 80%;
}

.blankState > p {
    width: 70%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(100,100,100, 0.6);
    font-weight: 300;
    margin: 0px !important;
}

.noResults-container {
    display: flex;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: auto;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.noResults-data-container {
    width: 100%;
    margin: 10px 10px 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noResults-data-container > img {
    width: 40px;
    margin-bottom: 10px;
    height: auto;
}

.noResults-data-container__title {
    margin: 0;
    text-align: center;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
}

.noResults-data-container__subtitle {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-top: 2px;
    color: #666666
}

.noResults-data-container__details {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: thin;
}

.noResults-data-container__link {
    color: white;
  background-color: #7cb936;
  font-weight: 600;
  text-transform: none;
  padding: 8px 14px;
  margin: 10px 0px 8px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.loading-container {
    display: flex;
    align-self: center;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 120px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.loading-img-container {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
}

.loading-avatar {
    height: 80%;
    margin-left: 20px;
}

.loading-data-container {
    width: 100%;
    margin: 10px 10px 10px 30px;
}

.loading-title {
    width: 60%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
}

.loading-subtitle {
    width: 40%;
    margin-bottom: 10px;

}

.loading-detail {
    width: 70%;
    margin: 0;
    padding: 0;
}
.divider-gray-selection {
  width: 100%;
  margin: 0;
  align-self: flex-start;
  border-bottom: 1px solid #ccc;
  margin-top: 2%;
}

.selection-title {
  font-size: 20px;
  margin-top: 0px;
  color: #7cb936;
}

.selection-subtitle {
  font-size: 16px;
  font-weight: normal;
  color: #666666;
}

.chips-container {
  container-type: inline-size;
}

.small_pill {
  margin-left: 0 !important;
  border-radius: 5px !important;
}

.small_pill > div {
  font-size: 1rem;
  font-weight: 600;
  color: #333 !important;
}

.small_pill.Mui-focused {
  outline: 2px solid #7cb936;
}

.green-bg {
  background-color: #7cb936 !important;
}

.small_pill.green-bg > div {
  color: white !important;
}

.Mui-focused.input-label:not(.text-white) {
  color: green !important;
}

.transparent-bg {
  background-color: #eee !important;
  height: 100%;
}

.transparent-bg > div:focus {
  background-color: transparent;
}

.input-label {
  font-size: 0.925rem !important;
  margin-left: 0;
}

.text-white {
  color: white !important;
}

.filters-grid {
  display: grid;
  grid-gap: 0.325rem;
  gap: 0.325rem;
}

@media (min-width: 500px) {
  .filters-grid {
    grid-template-columns: repeat(2, minmax(100px, 50%));
  }
}

@container (min-width: 500px) {
  .filters-grid {
    grid-template-columns: repeat(3, auto);
  }
}

.resultCard-container {
    display: flex;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 5px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.grow {
   -webkit-transform: scale(1.1);
           transform: scale(1.1);
   background-color: rgba(124, 185, 54, 0.1);
   border: solid 1px rgba(124, 185, 54, 0.7)
}

.resultCard-img-container {
    height: 100%;
    width: 25%;
    display: flex;
    align-items: center;
    padding: 5px 0px;
}

.resultCard-img-container > img {
    width: 100px;
    height: auto;
    margin-left: 20px;
    max-height:  -webkit-fill-available;
}

@media screen and (max-width: 1200px) {
    .resultCard-img-container > img {
        width: 80px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 400px) {
    .resultCard-img-container > img {
        width: 60px
    }
}

.resultCard-data-container {
    margin: 10px 10px 10px 30px;
    width: 100%;
}

.resultCard-data-container__subtitle {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #666666
}

@media screen and (max-width: 600px) {
    .resultCard-data-container__subtitle {
        font-size: 12px;
    }
}

.resultCard-data-container__details {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 200;
}

@media screen and (max-width: 600px) {
    .resultCard-data-container__details {
        font-size: 10px;
    }
}


* {
  font-family: "Inter" !important;
}

html {
  height: 100%;
  margin: 0;
}

.bg {
  background: url(/static/media/background.b4f3be26.webp);
  height: auto !important;
  min-height: calc(100vh - 40px);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

@media screen and (min-width:3000px) {
  .bg {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .form-contact {
    width: 80%;
  }
}

/* MANTENIMIENTO */

  .container-2{
    height: 92vh; 
    overflow-y: hidden;
  } 

  .logo{
    display: flex;
    width: 130px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .container-main{
    display: block;
    align-items: center;
    justify-content: center;
  }

  .container-texto h1{
    font-size: 60px;
    text-align: center;
    margin-bottom: 10px;
  }

  .container-texto h3{
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .container-texto p:last-child{
    text-align: center;
    margin-bottom: 5px;
  }

  .container-texto p{
    text-align: center;
  }

  .foto{
    display: block;
    margin-top: 30px;
    width: 350px;
    margin: auto;
  }

  .logoFundacion{
    width: 80px;
  }

  .logoMatea{
    width: 40px;
    height: 40px;
    margin-top: 5px;
  }

  .footer{
    background-color: #7cb936;  
  }

  .container-footer{
    padding: 10px;
    display: flex;
    justify-content: center;
  }


@media (min-width: 481px) and (max-width: 767px) {

  .logo{
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 200px;
  }

  .container-texto h1{
    font-size: 75px;
  }

  .container-texto h3{
    font-size: 25px;
  }

  .foto{
    width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .container-2{
    height: 91vh; 
    overflow-y: hidden;
  } 

  .logo{
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 220px;
  }

  .container-texto h1{
    font-size: 70px;
  }

  .foto{
    width: 400px;
  }
}

@media (min-width: 1025px) and (max-width: 1399px) {

  .container-2{
    height: 90vh; 
    overflow-y: hidden;
  } 

  .logo{
    margin-top: 30px;
    margin-left: 40px;
    width: 150px;
  }

  .container-main{
    display: flex;
    margin-top: 100px;
  }

  .container-texto h1{
    font-size: 80px;
    text-align: start;
  }

  .container-texto h3{
    text-align: start;
    font-size: 25px;
  }

  .container-texto p:last-child{
    text-align: start;
    margin-bottom: 23px;
  }

  .container-texto p{
    text-align: start;
  }

  .foto{
    padding-left: 50px;
    width: 500px;
  }

  .logoFundacion{
    width: 95px;
  }

  .logoMatea{
    width: 50px;
    height: 50px;
    margin-top: 5px;
  }

}

@media (min-width: 1400px) {

  .container-2{
    height: 91vh; 
    overflow-y: hidden;
  } 

  .logo{
    margin-top: 30px;
    margin-left: 40px;
    width: 180px;
  }

  .container-main{
    display: flex;
    margin: auto;
  }

  .container-texto h1{
    font-size: 100px;
    text-align: start;
  }

  .container-texto h3{
    text-align: start;
    font-size: 30px;
  }

  .container-texto p:last-child{
    text-align: start;
    margin-bottom: 23px;
  }

  .container-texto p{
    text-align: start;
  }

  .foto{
    padding-left: 180px;
    width: 800px;
  }

  .logoFundacion{
    width: 80px;
  }

  .logoMatea{
    width: 50px;
    height: 50px;
    margin-top: 5px;
  }
}
 

 /* HOME */
.filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 0px;
}

.my-future {
  color: white;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: "600px";
}

.my-future > img {
  margin: 0px auto 2rem auto;
  width: 300px;
}

.divider {
  display: none;
  width: 50%;
  margin: 0 auto;
  align-self: center;
  border-bottom: 1px solid white;
  margin-top: "15px";
}

.divider-gray {
  width: 50%;
  margin: 0 auto;
  align-self: center;
  border-bottom: 1px solid #ccc;
  margin: 20px 0px 40px 0px;
}

.carrousel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.colaborationContainer {
  display: flex;
  width: 100%;
  background-color: #00000032;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  padding: 10px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  height: auto;
}

.logoFundacion {
  width: auto;
  height: 80px;
  margin-right: 4%;
}

.x {
  font-weight: 200;
  font-size: 36px;
  color: white;
}

.logoMatea {
  width: 80px;
  height: auto;
}

/* ADMIN */
.admin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgb(77, 77, 77);
}

.container {
  width: 900px;
  height: 840px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title_admin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 35px;
  color: aliceblue;
  margin: 10px;
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.inputAdmin {
  margin: 10px;
  width: 400px;
  height: 50px;
}

label {
  margin-left: 10px;
  font-size: 15px;
  color: aliceblue;
}
textarea {
  margin: 10px;
  width: 400px;
  height: 120px;
}

.btn_admin {
  background-color: #7cb936;
  width: 100%;
  height: 50px;
}


/* RESULTS */
.content-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 115px);
  width: 100%;
  justify-content: space-evenly;
}

.left-container {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  margin-right: 35px;
}

.img-wraper {
  position: relative; 
  width: 100%;
}

.img-overlay {
  width: 60%; /* Ya está como atibuto, por si acaso */
  position: absolute;
  left: 20%;
  height: 200px;
  object-fit: cover;
}

.circular-prog {
  position: absolute;
  z-index: -1;
  left: 45%; 
  margin-top: 50px;
}

.pre-load {
  visibility: hidden;
  width: 60%;
  flex-shrink: 0;
  height: 200px;
}

@media (min-width: 1366px) {
  .pre-load, .img-overlay {
    height: 300px;
  }
}

@media (max-width: 300px) {
  .circular-prog {
    margin-top: 10px;
  }
}

@media (min-width: 1500px) {
  .circular-prog {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1000px) {
.content-container {
  justify-content: center;
}

.left-container {
  width: 80%;
  margin: 0px;
}
 
.vertical-divider {
  display: none;
}

.right-container {
  display: none !important;
}

}

.results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.results-container::-webkit-scrollbar {
  display: none;
}

.vertical-divider {
  align-self: center;
  height: 100%;
  width: 1px;
  border-left: 1px solid #ccc;
}

.right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: 100%;
  margin-left: 45px;
}

.right-container img {
  max-width: 60%;
  max-height: 340px;
  align-self: center;
  margin-bottom: 25px;
  border-radius: 5px;
}

.right-title {
  align-self: center;
  margin-bottom: 0px !important;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.right-subtitle {
  align-self: center;
  margin: 2px 0px 0px 0px !important;
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.contact-link {
  margin-top: 25px;
  text-decoration: none !important;
  align-self: center;
}

.slide {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px 40px 30px 40px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.lists {
  width: auto;
}

.circle {
  min-width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7cb936;
  border-radius: 100%;
}

.img-icon {
  width: 15px;
  color: white;
}

.contact {
  padding-left: 10px;
}

.white {
  color: whitesmoke;
}

.text-web {
  word-break: break-all;
}

li {
  margin-bottom: 10px;
}

.li {
  list-style-type: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slide::-webkit-scrollbar {
  display: none;
}
.description-paragraph {
  text-align: justify;
  font-size: 14px;
  font-weight: 380px;
  align-self: left;
  line-height: 1.5;
}

.description-li {
  text-align: justify;
  font-size: 14px;
  font-weight: 380px;
  line-height: 1.5;
  margin: 0px;
  margin-bottom: 15px !important;
}

.slide > ul > li {
  text-align: justify;
}

/* MORE INFORMATION */

.detail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  height: calc(100% - 120px);
  margin: 0 auto;
}

.detail-container img {
  max-width: 60%;
  max-height: 340px;
  align-self: center;
  margin-bottom: 25px;
  border-radius: 5px;
}

.detail-detail {
  width: 100%;
  height: 92%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px 40px 30px 40px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

@media screen and (max-width: 768px) {
  .detail-detail {
    width: 200%;
    margin: 10px 0px;
    padding: 10px 15px 20px 15px;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.detail-detail::-webkit-scrollbar {
  display: none;
}

.detail-title {
  align-self: center;
  margin-bottom: 0px !important;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.detail-subtitle {
  align-self: center;
  margin: 2px 0px 0px 0px !important;
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
}

.detail-container_error {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: calc(100% - 120px);
  margin: 0 auto;
  text-align: center;
}
.detail-subtitle_error {
  align-self: center;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

/*ABOUT US*/

.about {
  width: 90%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5em;
}

.about-wraper {
  display: flex;
  flex-direction: column;
}

.upm-container {
  display: flex;
 width: 100%;
 flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.upm-data {
  height: 100%;
  grid-column: 1;
} 

.col-span-1 {
  grid-column: 1;
}

.upm-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2em;
}

.upm_video {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upm-text {
    width: 100%;
    height: 100%;
    text-align: justify;
    margin-bottom: 3em;
}

.matea-text {
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: justify;
  flex-basis: 80%;
}

.matea-container {
  display: flex;
  flex-direction: column-reverse;
}

.text-display {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.matea-images {
  width: 100%;
}

.matea-images-wraper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.matea-staff {
  height: 250px;
  object-fit: contain;
  object-position: top;
}

.create-soft {
  padding-right: 1em;
  width: 50%;
  align-self: center;
}

.about_link-web {
  color: #7cb936;
}

.btn-green-about {
  color: white !important;
  background-color: #7cb936 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 8px 14px !important;
  border-radius: 5px !important;
}

.btn-green-about-2 {
  color: white !important;
  background-color: #7cb936 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 8px 14px !important;
  border-radius: 5px !important;
}

.button-about{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}


.m-2 {
  margin-top: 1em !important; 
  margin-bottom: 1em !important;
}

.sites-links {
  text-decoration: none;
}


.line-through {
  width: 95%;
  margin: 4em 0;
}
@media (min-width: 1366px) {
  .upm_video { 
  height: 35vh;
  width: 100%;
  flex-basis: 125%;
  }
  .upm-container {
    display: grid;
  }

  .upm-data {
    padding-right: 2em;
    padding-bottom: 0;
  }

  .matea-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .m-3 {
    margin-left: -2em;
  }

  .about {
    width: 60%;
  }
}

/*CONTACT*/
.page-contact {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.content_form-contact {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  height: 80vh;
}

.content-form-contact {
  width: 70%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.form-contact_title {
  width: auto;
  color: #7cb936;
  font-size: 30px;
  padding: 20px;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0
}
.box-form {
  text-align: center;
}

.textfield {
  width: 100%;
  padding: 0px;
}

.btn-green-contact {
  color: white !important;
  background-color: #7cb936 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 8px 14px !important;
  margin: 0px 0px 8px 8px !important;
  border-radius: 5px !important;
  width: 220px;
  margin: 300px;
}
.footer_contact {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  text-align: center;
}

@media screen and (max-width: 4000px){
  .colaborationContainer{
    margin-bottom: 0!important;
  }
}

@media screen and (max-width: 1000px) {
  .my-future {
    margin: 0px !important;
    padding: 20px 40px;
  }
  .divider {
    display: flex;
    margin-top: 10px;
  }
  .details {
    height: auto;
  }
  .colaborationContainer{
    margin-bottom: 0!important;
  }

}

@media screen and (max-width: 900px) {
  .divider {
    margin: 0px auto 10px auto;
    width: 50%;
  }
  .Home_container-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
  }
  .form-contact_title {
    font-size: 20px;
    text-align: center;
  }
  .form-contact {
    width: 70%;
  }
  .btn-green {
    margin: 0px;
  }
  .colaborationContainer{
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 490px) {
  /*home*/
  .my-future {
    padding: 18px;
  }
  .logoFundacion {
    width: 80px;
    height: auto;
    margin-right: 5%;
  }
  .logoMec{
    width: 130px;
    margin-left: 4%;
  }
  .colaborationContainer{
    margin-bottom: 0!important;
  }
  .my-future > img {
    margin: 20px auto 50px auto !important;
  }
  /*about*/
  .about {
    width: "100vw";
  }
  .upm {
    margin: 0px;
    padding: 20px 20px;
  }
  .matea {
    margin: 0;
    padding: 20px 20px;
  }
  .form-contact {
    width: 70%;
  }
}

@media screen and (max-width: 350px) {
  .form-contact_title {
    font-size: 18px;
    text-align: center;
  }
  .form-contact {
    width: 90%;
  }
  .logoFundacion {
    width: 80px;
    height: auto;
    margin-right: 5%;
  }
  .logoMec{
    width: 130px;
    margin-left: 4%;
  }
  .colaborationContainer{
    margin-bottom: 0;
  }
}

.logoMatea {
  width: 55px;
  height: auto;
}
.logoFundacion {
  width: 80px;
  height: auto;
}
.logoMec{
  width: 130px;
  margin-left: 3%;
}

/*SLIDER*/

.slider {
	height: 250px;
	margin: auto;
	position: relative;
	width: 90%;
	display: grid;
	place-items: center;
	overflow: hidden;
}

.slide-outer {
  width: 100%;
  overflow-x: hidden;
}

.slide-track {
  margin-top: 50px;
  margin-bottom: 50px;
	display: flex;
	width: calc(250px * 18);
	-webkit-animation: scroll 200s linear infinite;
	        animation: scroll 200s linear infinite;
}

.slide-track:hover {
	-webkit-animation-play-state: paused;
	        animation-play-state: paused;
}

@-webkit-keyframes scroll{
	0%{
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	100%{
		-webkit-transform: translateX(calc(-250px * 9));
		        transform: translateX(calc(-250px * 9));
	}
}

@keyframes scroll{
	0%{
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}
	100%{
		-webkit-transform: translateX(calc(-250px * 9));
		        transform: translateX(calc(-250px * 9));
	}
}

.slide-item{
	display: flex;
	align-items: center;
	padding: 0px;
  border-radius: 0px;
}

.foto-slider{
	width: 100%;
	transition: -webkit-transform 2s;
	transition: transform 2s;
	transition: transform 2s, -webkit-transform 2s;
  border-radius: 0px;
}

.foto-slider:hover{
	-webkit-transform: translateZ(20px);
	        transform: translateZ(20px);
  border-radius: 0px;
}

.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: calc(100% - 120px);
  margin: 0 auto;
  text-align: center;
}

.filters-container {
  width: auto;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 100px;
  padding: 10px 40px;
  justify-content: center;
  margin-bottom: 40px;
}

.filters-title {
  text-align: center;
  color: #ccc;
  font-size: 1em;
}

.select-container-first {
  display: flex;
  padding: 0px 35px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #7cb936;
}

.select-container {
  display: flex;
  padding: 0px 10px 0px 35px;
  flex-direction: column;
  align-items: flex-start;
  /*border-right: 1px solid #7cb936;*/
}

.select-container-last {
  display: flex;
  padding: 0px 25px 0px 25px;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid #7cb936;
}
.select-filter {
  min-width: 150px;
  width: auto;
}

.filters-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.filters-img {
  width: 50px;
  height: 50px;
  margin-right: -25px;
}

ul {
  display: flex;
  flex-direction: column;
}

.btn-green {
  color: white !important;
  background-color: #2e7d32 !important;
  padding: 0px 5px !important;
  border-radius: 40px;
}

.inputLabel {
  font-size: 13px;
}

@media screen and (max-width: 900px) {
  .filters-container {
    margin-bottom: 0px;
    padding: 10px 20px;
  }

  .filters-form {
    margin-bottom: 0px;
    justify-content: center;
    flex-direction: column;
  }

  .filters-title {
    font-size: 12px;
    margin: 2px;
  }

  .select-container {
    border-right: none;
    padding: 0px 0px 0px 25px;
  }
  .select-container-first {
    padding: 0px 25px 0px 0px;
  }
}

@media screen and (max-width: 730px) {
  .filters-container {
    width: auto;
  }
  .filters-form {
    width: auto;
  }
  .select-container-last {
    padding: 0px 20px 0px 10px;
  }
  .select-container-first {
    padding: 0px 20px 0px 0px;
  }
  .select-container {
    padding: 0px 0px 0px 20px;
  }
}

@media screen and (max-width: 630px) {
  .filters-container {
    height: 50px;
  }
  .inputLabel {
    font-size: 13px !important;
  }
  .menuItem {
    font-size: 13px !important;
  }
  .select-filter {
    min-width: 110px !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 530px) {
  .filters-container {
    flex-direction: column;
    width: 100%;
    padding: 0px;
    height: 200px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .filters-form {
    flex-direction: column;
    padding: 0px;
    width: 80%;
    height: auto;
    border-radius: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .select-container-first {
    width: 280px;
    padding: 0px;
    border-right: 0px;
    border-bottom: 1px solid #7cb936;
    margin-bottom: 10px;
  }
  .select-container-last {
    width: 280px;
    padding: 0px;
    border-left: none;
    border-right: 0px;
    border-bottom: 1px solid #7cb936;
    margin-bottom: 10px;
  }
  .select-container {
    width: 280px;
    padding: 0px;
    border-right: 0px;
    border-bottom: 1px solid #7cb936;
    margin-bottom: 10px;
  }
  .select-filter {
    width: 100%;
    min-width: 130px !important;
  }
}

@media screen and (max-width: 490px) {
  .filters-container {
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 10px;
    padding: 20px 10px;
    margin-bottom: 10px;
  }
  .filters-form {
    flex-direction: column;
    padding: 0px;
    width: 80%;
    height: auto;
    border-radius: 40px;
    margin-bottom: 10px;
  }
  .filters-title {
    font-size: 15px;
    margin: "2px";
  }
  .select-filter {
    width: 100%;
    min-width: 230px !important;
  }
  .btn-green {
    border-radius: 0px;
    margin: 0px;
  }
}

@media screen and (max-width: 400px) {
  .filters-container {
    width: 100%;
  }
  .select-container-first {
    width: 250px;
  }
  
  .select-container-last {
    width: 250px;
  }
  .select-container {
    width: 250px;
  }
}

.cards-container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-body {
  border-radius: 10px !important;
  width: 360px;
  height: auto !important;
  margin: 7px 25px;
}

.card-content {
 padding: 20px;
 width: auto;
 height: 140px;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
}

.p-card {
  margin-top: 0px;
  margin-bottom: 10px;
}

.btn-green {
  color: white !important;
  background-color: #7cb936 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  padding: 8px 14px !important;
  margin: 0px 0px 8px 8px !important;
  border-radius: 5px !important;
}
.cards-loading {
  min-height: 970px;
  padding-top: 50px;
  margin: 0;
  display: flex;
}

@media screen and (max-width: 490px) {
  .card-body {
    width: auto;
    height: auto;
    margin: 5px 10px;
  }
  .cards-loading {
    min-height: 1080px;
    padding-top: 30px;
    margin: 0;
    display: flex;
  }
}

@media screen and (min-width: 900px) {
    .cards-container {
        width: auto;
        height: auto;
    }
    .card-body {
        width: 250px;
    }
    .cards-loading {
      max-height: 372px;
      min-height: 372px;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      position: relative;
      right: 20px
    }
}

@media screen and (min-width: 1200px) {
    .card-body {
        width: 330px;
        height: 275px;
        margin: 7px 25px;
    }
}
.footer-white {
    width: auto;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-green {
    width: auto;
    background-color:#f2f8eb;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 790px) {
    .footer-green {
        padding: 15px 15px;
        width: auto;
        height: auto;
    }
    .footer-white {
        padding: 15px 15px;
        width: auto;
        height: auto;
    }
}


