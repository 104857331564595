.divider-gray-selection {
  width: 100%;
  margin: 0;
  align-self: flex-start;
  border-bottom: 1px solid #ccc;
  margin-top: 2%;
}

.selection-title {
  font-size: 20px;
  margin-top: 0px;
  color: #7cb936;
}

.selection-subtitle {
  font-size: 16px;
  font-weight: normal;
  color: #666666;
}

.chips-container {
  container-type: inline-size;
}

.small_pill {
  margin-left: 0 !important;
  border-radius: 5px !important;
}

.small_pill > div {
  font-size: 1rem;
  font-weight: 600;
  color: #333 !important;
}

.small_pill.Mui-focused {
  outline: 2px solid #7cb936;
}

.green-bg {
  background-color: #7cb936 !important;
}

.small_pill.green-bg > div {
  color: white !important;
}

.Mui-focused.input-label:not(.text-white) {
  color: green !important;
}

.transparent-bg {
  background-color: #eee !important;
  height: 100%;
}

.transparent-bg > div:focus {
  background-color: transparent;
}

.input-label {
  font-size: 0.925rem !important;
  margin-left: 0;
}

.text-white {
  color: white !important;
}

.filters-grid {
  display: grid;
  gap: 0.325rem;
}

@media (min-width: 500px) {
  .filters-grid {
    grid-template-columns: repeat(2, minmax(100px, 50%));
  }
}

@container (min-width: 500px) {
  .filters-grid {
    grid-template-columns: repeat(3, auto);
  }
}
