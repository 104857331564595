.footer-white {
    width: auto;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-green {
    width: auto;
    background-color:#f2f8eb;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media screen and (max-width: 790px) {
    .footer-green {
        padding: 15px 15px;
        width: auto;
        height: auto;
    }
    .footer-white {
        padding: 15px 15px;
        width: auto;
        height: auto;
    }
}

