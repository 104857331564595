.noResults-container {
    display: flex;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: auto;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.noResults-data-container {
    width: 100%;
    margin: 10px 10px 10px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noResults-data-container > img {
    width: 40px;
    margin-bottom: 10px;
    height: auto;
}

.noResults-data-container__title {
    margin: 0;
    text-align: center;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
}

.noResults-data-container__subtitle {
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-top: 2px;
    color: #666666
}

.noResults-data-container__details {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: thin;
}

.noResults-data-container__link {
    color: white;
  background-color: #7cb936;
  font-weight: 600;
  text-transform: none;
  padding: 8px 14px;
  margin: 10px 0px 8px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}