.blankState {
    width: 100%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px 40px 30px 40px;
    opacity: 0.7;
    z-index: -1;
}

@media screen and (max-width: 1000px) {
    .blankState {
      display: none;
    }
}

.blankState > h1 {
    width: 90%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: rgba(100,100,100, 0.6);
    font-weight: 500;
}

.blankState > img {
    width: 80%;
}

.blankState > p {
    width: 70%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(100,100,100, 0.6);
    font-weight: 300;
    margin: 0px !important;
}
