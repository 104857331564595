.loading-container {
    display: flex;
    align-self: center;
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 120px;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.loading-img-container {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
}

.loading-avatar {
    height: 80%;
    margin-left: 20px;
}

.loading-data-container {
    width: 100%;
    margin: 10px 10px 10px 30px;
}

.loading-title {
    width: 60%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
}

.loading-subtitle {
    width: 40%;
    margin-bottom: 10px;

}

.loading-detail {
    width: 70%;
    margin: 0;
    padding: 0;
}