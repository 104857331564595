.filters-container {
  width: auto;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 100px;
  padding: 10px 40px;
  justify-content: center;
  margin-bottom: 40px;
}

.filters-title {
  text-align: center;
  color: #ccc;
  font-size: 1em;
}

.select-container-first {
  display: flex;
  padding: 0px 35px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid #7cb936;
}

.select-container {
  display: flex;
  padding: 0px 10px 0px 35px;
  flex-direction: column;
  align-items: flex-start;
  /*border-right: 1px solid #7cb936;*/
}

.select-container-last {
  display: flex;
  padding: 0px 25px 0px 25px;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid #7cb936;
}
.select-filter {
  min-width: 150px;
  width: auto;
}

.filters-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.filters-img {
  width: 50px;
  height: 50px;
  margin-right: -25px;
}

ul {
  display: flex;
  flex-direction: column;
}

.btn-green {
  color: white !important;
  background-color: #2e7d32 !important;
  padding: 0px 5px !important;
  border-radius: 40px;
}

.inputLabel {
  font-size: 13px;
}

@media screen and (max-width: 900px) {
  .filters-container {
    margin-bottom: 0px;
    padding: 10px 20px;
  }

  .filters-form {
    margin-bottom: 0px;
    justify-content: center;
    flex-direction: column;
  }

  .filters-title {
    font-size: 12px;
    margin: 2px;
  }

  .select-container {
    border-right: none;
    padding: 0px 0px 0px 25px;
  }
  .select-container-first {
    padding: 0px 25px 0px 0px;
  }
}

@media screen and (max-width: 730px) {
  .filters-container {
    width: auto;
  }
  .filters-form {
    width: auto;
  }
  .select-container-last {
    padding: 0px 20px 0px 10px;
  }
  .select-container-first {
    padding: 0px 20px 0px 0px;
  }
  .select-container {
    padding: 0px 0px 0px 20px;
  }
}

@media screen and (max-width: 630px) {
  .filters-container {
    height: 50px;
  }
  .inputLabel {
    font-size: 13px !important;
  }
  .menuItem {
    font-size: 13px !important;
  }
  .select-filter {
    min-width: 110px !important;
    font-size: 12px !important;
  }
}

@media screen and (max-width: 530px) {
  .filters-container {
    flex-direction: column;
    width: 100%;
    padding: 0px;
    height: 200px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .filters-form {
    flex-direction: column;
    padding: 0px;
    width: 80%;
    height: auto;
    border-radius: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .select-container-first {
    width: 280px;
    padding: 0px;
    border-right: 0px;
    border-bottom: 1px solid #7cb936;
    margin-bottom: 10px;
  }
  .select-container-last {
    width: 280px;
    padding: 0px;
    border-left: none;
    border-right: 0px;
    border-bottom: 1px solid #7cb936;
    margin-bottom: 10px;
  }
  .select-container {
    width: 280px;
    padding: 0px;
    border-right: 0px;
    border-bottom: 1px solid #7cb936;
    margin-bottom: 10px;
  }
  .select-filter {
    width: 100%;
    min-width: 130px !important;
  }
}

@media screen and (max-width: 490px) {
  .filters-container {
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 10px;
    padding: 20px 10px;
    margin-bottom: 10px;
  }
  .filters-form {
    flex-direction: column;
    padding: 0px;
    width: 80%;
    height: auto;
    border-radius: 40px;
    margin-bottom: 10px;
  }
  .filters-title {
    font-size: 15px;
    margin: "2px";
  }
  .select-filter {
    width: 100%;
    min-width: 230px !important;
  }
  .btn-green {
    border-radius: 0px;
    margin: 0px;
  }
}

@media screen and (max-width: 400px) {
  .filters-container {
    width: 100%;
  }
  .select-container-first {
    width: 250px;
  }
  
  .select-container-last {
    width: 250px;
  }
  .select-container {
    width: 250px;
  }
}
